// /* @flow */
// import _ from 'lodash'
import * as actionTypes from '../actionTypes'
/* import type {
  SimpleAction,
  InventoryAction,
  ClickValidateInventoryAction,
  ChangeFieldInventoryAction,
} from '../flowTypes/actions' */

export const changeInventoryField = (field /* : string */, value /* : string | number */) /* : ChangeFieldInventoryAction */ => ({
  type: actionTypes.CHANGE_INVENTORY_FIELD,
  field,
  value,
})

export const cancelInventory = () /* : SimpleAction */ => ({ type: actionTypes.CANCEL_INVENTORY })

export const copyInventory = (inventoryId /* : string */) /* : InventoryAction */ => ({
  type: actionTypes.COPY_INVENTORY,
  inventoryId,
})

export const editInventory = (inventoryId /* : string */) /* : InventoryAction */ => ({
  type: actionTypes.EDIT_INVENTORY,
  inventoryId,
})

export const validateInventory = (
  formErrors /* : {
  [string]: boolean,
} */
) /* : ClickValidateInventoryAction */ => ({
  type: actionTypes.VALIDATE_INVENTORY,
  formErrors,
})

export const toggleInventoryField = field => ({
  type: actionTypes.TOGGLE_INVENTORY_FIELD,
  field,
})
