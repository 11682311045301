import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore, compose } from 'redux'
import thunk from 'redux-thunk'
import { ThemeProvider } from 'styled-components'
import './utils/globalCss'
import App from './components/App'
import rootReducer from './rootReducer'
import theme from './utils/theme'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))

const render = Component => {
  ReactDOM.render(
    <Provider store={store}>
      <AppContainer>
        <ThemeProvider theme={theme}>
          <Component />
        </ThemeProvider>
      </AppContainer>
    </Provider>,
    document.getElementById('sr-app-upsells')
  )
}

render(App)

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./components/App', () => {
    render(App)
  })
}
