// /* @flow */
import { srGet } from '@sevenrooms/core/api'
import * as actionTypes from '../actionTypes'
import { transformDataFromGetApi } from '../utils/transformData'
/* import type { Dispatch, GetState } from '../flowTypes/redux' */
/* import type { Action } from '../flowTypes/actions' */

const API_NAMESPACE = '/api-yoa'

export const getData = () => async (dispatch /* : Dispatch */, getState /* : GetState */) => {
  const state = getState()
  dispatch(dataGetAttempt())
  const getResponse = await srGet(`${API_NAMESPACE}/venue/upsells/list`, {
    venue: state.main.venueId,
  })
  return getResponse.status === 200
    ? dispatch(dataGetSuccess(transformDataFromGetApi(getResponse.data, state.main)))
    : dispatch(dataGetFailure())
}

// HTTP Action creators
const dataGetAttempt = () /* : Action */ => ({ type: actionTypes.DATA_GET_ATTEMPT })
const dataGetSuccess = (payload) /* : Action */ => ({
  type: actionTypes.DATA_GET_SUCCESS,
  payload,
})
const dataGetFailure = () /* : Action */ => ({ type: actionTypes.DATA_GET_FAILURE })
