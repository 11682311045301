// /* @flow */
export const SET_VIEW = 'upsells/SET_VIEW'
export const DISPLAY_MODAL = 'upsells/DISPLAY_MODAL'

export const SHOW_NOTIFICATION_BANNER = 'SHOW_NOTIFICATION_BANNER'
export const CLICK_NOTIFICATION_BANNER_ACTION = 'CLICK_NOTIFICATION_BANNER_ACTION'
export const CLOSE_NOTIFICATION_BANNER = 'CLOSE_NOTIFICATION_BANNER'

export const CHANGE_CATEGORY_FIELD = 'upsells/CHANGE_CATEGORY_FIELD'
export const TOGGLE_CATEGORY_FIELD = 'upsells/TOGGLE_CATEGORY_FIELD'
export const CHANGE_INVENTORY_FIELD = 'upsells/CHANGE_INVENTORY_FIELD'
export const TOGGLE_INVENTORY_FIELD = 'upsells/TOGGLE_INVENTORY_FIELD'

export const ADD_CATEGORY = 'upsells/ADD_CATEGORY'
export const COPY_CATEGORY = 'upsells/COPY_CATEGORY'
export const EDIT_CATEGORY = 'upsells/EDIT_CATEGORY'
export const CANCEL_CATEGORY = 'upsells/CANCEL_CATEGORY'
export const VALIDATE_CATEGORY = 'upsells/VALIDATE_CATEGORY'
export const SAVE_CATEGORY = 'upsells/SAVE_CATEGORY'
export const CLICK_DELETE_CATEGORY = 'upsells/CLICK_DELETE_CATEGORY'
export const CANCEL_DELETE_CATEGORY = 'upsells/CANCEL_DELETE_CATEGORY'
export const MOVE_CATEGORY_UP = 'upsells/MOVE_CATEGORY_UP'
export const MOVE_CATEGORY_DOWN = 'upsells/MOVE_CATEGORY_DOWN'

export const ADD_INVENTORY = 'upsells/ADD_INVENTORY'
export const COPY_INVENTORY = 'upsells/COPY_INVENTORY'
export const EDIT_INVENTORY = 'upsells/EDIT_INVENTORY'
export const CANCEL_INVENTORY = 'upsells/CANCEL_INVENTORY'
export const VALIDATE_INVENTORY = 'upsells/VALIDATE_INVENTORY'
export const SAVE_INVENTORY = 'upsells/SAVE_INVENTORY'
export const MOVE_INVENTORY_UP = 'upsells/MOVE_INVENTORY_UP'
export const MOVE_INVENTORY_DOWN = 'upsells/MOVE_INVENTORY_DOWN'

// HTTP Requests
export const DATA_GET_ATTEMPT = 'upsells/DATA_GET_ATTEMPT'
export const DATA_GET_SUCCESS = 'upsells/DATA_GET_SUCCESS'
export const DATA_GET_FAILURE = 'upsells/DATA_GET_FAILURE'

export const CATEGORY_POST_ATTEMPT = 'upsells/CATEGORY_POST_ATTEMPT'
export const CATEGORY_POST_SUCCESS = 'upsells/CATEGORY_POST_SUCCESS'
export const CATEGORY_POST_FAILURE = 'upsells/CATEGORY_POST_FAILURE'
export const CATEGORY_PUT_ATTEMPT = 'upsells/CATEGORY_PUT_ATTEMPT'
export const CATEGORY_PUT_SUCCESS = 'upsells/CATEGORY_PUT_SUCCESS'
export const CATEGORY_PUT_FAILURE = 'upsells/CATEGORY_PUT_FAILURE'
export const CATEGORY_DELETE_ATTEMPT = 'upsells/CATEGORY_DELETE_ATTEMPT'
export const CATEGORY_DELETE_SUCCESS = 'upsells/CATEGORY_DELETE_SUCCESS'
export const CATEGORY_DELETE_FAILURE = 'upsells/CATEGORY_DELETE_FAILURE'
export const REORDER_CATEGORIES_ATTEMPT = 'upsells/REORDER_CATEGORIES_ATTEMPT'
export const REORDER_CATEGORIES_SUCCESS = 'upsells/REORDER_CATEGORIES_SUCCESS'
export const REORDER_CATEGORIES_FAILURE = 'upsells/REORDER_CATEGORIES_FAILURE'
export const CATEGORY_COPY_ATTEMPT = 'upsells/CATEGORY_COPY_ATTEMPT'
export const CATEGORY_COPY_SUCCESS = 'upsells/CATEGORY_COPY_SUCCESS'
export const CATEGORY_COPY_FAILURE = 'upsells/CATEGORY_COPY_FAILURE'
export const CHANGE_TAX_GROUP_ID = 'upsells/CHANGE_TAX_GROUP_ID'

export const INVENTORY_POST_ATTEMPT = 'upsells/INVENTORY_POST_ATTEMPT'
export const INVENTORY_POST_SUCCESS = 'upsells/INVENTORY_POST_SUCCESS'
export const INVENTORY_POST_FAILURE = 'upsells/INVENTORY_POST_FAILURE'
export const INVENTORY_PUT_ATTEMPT = 'upsells/INVENTORY_PUT_ATTEMPT'
export const INVENTORY_PUT_SUCCESS = 'upsells/INVENTORY_PUT_SUCCESS'
export const INVENTORY_PUT_FAILURE = 'upsells/INVENTORY_PUT_FAILURE'
export const INVENTORY_DELETE_ATTEMPT = 'upsells/INVENTORY_DELETE_ATTEMPT'
export const INVENTORY_DELETE_SUCCESS = 'upsells/INVENTORY_DELETE_SUCCESS'
export const INVENTORY_DELETE_FAILURE = 'upsells/INVENTORY_DELETE_FAILURE'
export const REORDER_INVENTORIES_ATTEMPT = 'upsells/REORDER_INVENTORIES_ATTEMPT'
export const REORDER_INVENTORIES_SUCCESS = 'upsells/REORDER_INVENTORIES_SUCCESS'
export const REORDER_INVENTORIES_FAILURE = 'upsells/REORDER_INVENTORIES_FAILURE'
