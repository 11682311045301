// /* @flow */
export const viewTypes = {
  VIEW_CATEGORIES: 'VIEW_CATEGORIES',
  EDIT_CATEGORY: 'EDIT_CATEGORY',
}

export const modalTypes = {
  EDIT_INVENTORY: 'EDIT_INVENTORY',
  DELETE_CATEGORY: 'DELETE_CATEGORY',
}

export const inventoryFormFields = {
  INVENTORY_TYPE_NAME: 'inventoryTypeName',
  PRICE: 'price',
  DESCRIPTION: 'description',
  PREVIEW_IMAGE: 'previewImageUrlKey',
  RESERVATION_TAGS: 'reservationTags',
  IS_PRICE_HIDDEN: 'isPriceHidden',
  POS_ITEM_ID: 'posItemId',
}

export const categoryFormFields = {
  CATEGORY_NAME: 'categoryName',
  IS_NAME_SHOWN: 'isNameShown',
  MAX_QUANTITY_TYPE: 'maxQuantityType',
  MAX_QUANTITY_VALUE: 'maxQuantityValue',
  MIN_QUANTITY_TYPE: 'minQuantityType',
  MIN_QUANTITY_VALUE: 'minQuantityValue',
  IS_CHARGING_TAX: 'isChargingTax',
  IS_CHARGING_GRATUITY: 'isChargingGratuity',
  REQUIRE_CHARGING_GRATUITY: 'requireGratuityCharge',
  GRATUITY_CHARGE_TYPE: 'gratuityChargeType',
  GRATUITY_PERCENTAGE: 'gratuityPercentage',
  TAX_GROUP_ID: 'taxGroupId',
  IS_CHARGING_SERVICE_CHARGE: 'isChargingServiceCharge',
  SERVICE_CHARGE_TYPE: 'serviceChargeType',
  SERVICE_CHARGE_PERCENTAGE: 'serviceChargePercentage',
  MAX_QUANTITY_ALLOW_MULTIPLES: 'maxQuantityAllowMultiples',
  ADVANCED_UPGRADES: 'advancedPDRUpgrades',
  ADVANCED_DESCRIPTION: 'advancedDescription',
}

export const maxQuantityTypes = {
  UNLIMITED: 'UNLIMITED',
  NUMBER_OF_GUESTS: 'NUMBER_OF_GUESTS',
  SPECIFIC_NUMBER: 'SPECIFIC_NUMBER',
}

export const minQuantityTypes = {
  NONE_REQUIRED: 'NONE_REQUIRED',
  NUMBER_OF_GUESTS: 'NUMBER_OF_GUESTS',
  SPECIFIC_NUMBER: 'SPECIFIC_NUMBER',
}

export const chargeGratuityTypes = {
  DEFAULT_GRATUITY: 'DEFAULT_GRATUITY',
  SPECIFIC_GRATUITY: 'SPECIFIC_GRATUITY',
  CLIENT_GRATUITY: 'CLIENT_GRATUITY',
}

export const chargeServiceChargeTypes = {
  DEFAULT_SERVICE_CHARGE: 'DEFAULT_SERVICE_CHARGE',
  SPECIFIC_SERVICE_CHARGE: 'SPECIFIC_SERVICE_CHARGE',
}
